import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'

import mainHeader from '../../../images/solutions/rpa/improvement.jpg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { FaqSection } from '../../../components/faq'
import { rpaList } from '../../../utils/customersList'
import clsx from 'clsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FaChevronRight } from 'react-icons/fa'
import BreadcrumbComponent from '../../../components/breadcrumb'
import { StaticImage } from 'gatsby-plugin-image'

const faqItems = [
  {
    question: 'Porque o Hi-Performance é essencial em projetos RPA?',
    answer:
      'A etapa Hi-Performance é essencial porque evita impactos negativos na operação, garantindo que processos não sejam interrompidos; aprimora a automação, através de ajustes de velocidade e eficiência; e aumenta a adoção pelos usuários, reduzindo resistências culturais.',
  },
  {
    question: 'Por que contratar o serviço Hi-performance?',
    answer:
      'Porque nossa abordagem holística abrange o monitoramento contínuo das automações, gestão de incidentes e correção de erros, melhoria contínua de bots que sofreram alterações em regras de negócio ou sistemas, manutenção preventiva e treinamento para garantir o uso correto das ferramentas.',
  },
  {
    question: 'Como é feito o monitoramento e sustentação das automações?',
    answer:
      'Contamos com o atendimento de chamados de suporte técnico, realizado via sistema, basta solicitar online a qualquer tempo. Para monitoramento dos robôs, desenvolvemos internamente dashboards com atualizações em tempo real, que mostram as execuções bem sucedidas, falhas, logs e rastreabilidade, índices de eficiência e ganhos de produtividade em FTE (full time equivalence).',
  },
  {
    question: 'Quais são os recursos alocados para prestação dos serviços?',
    answer:
      'Para prestação dos serviços alocamos uma equipe multidisciplinar composta por profissionais especializados em gestão por processos (BPM), desenvolvedores com experiência do low ao hard code e gestores de projeto, com a competência necessária para garantir o resultado do processo robotizado.',
  },
]

const PerformancePage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location

  return (
    <Layout location={location}>
      <SEO title="High Jump - Hi-Performance" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>Hi-Performance</h1>
              <p>
                Monitore e mensure o impacto de um projeto de automação robótica
                de processos (RPA). Obtenha dados confiáveis em tempo real da
                produtividade de robôs e alavanque sua operação.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Automação Robótica de Processos"
              replacedLabel="Hi-Performance"
            />
            <div className={styles.hiRpaSection}>
              <div className={styles.title}>
                <h1>O que é o Hi-Performance?</h1>
                <p>
                  É o serviço de suporte, sustentação e performance de robôs
                  (RPA) para sua plena execução. Contempla o acompanhamento e
                  gestão de indicadores através de B.I e infográficos.
                </p>
              </div>
              <div className={styles.content}>
                <p>
                  A ausência de um trabalho adequado de{' '}
                  <strong>suporte, sustentação e otimização</strong> pós
                  implementação RPA, pode impactar negativamente tanto nas
                  automações (bots), como na própria operação da empresa.
                </p>
                <p>
                  Os impactos negativos podem anular os benefícios da automação,
                  desperdiçando recursos financeiros e intelectuais do negócio,
                  resultando em:
                </p>
                <ul>
                  <li>Falhas de execução não detectadas</li>
                  <li>Interrupção de processos gerando atrasos </li>
                  <li>Perda de eficiência de robôs </li>
                  <li>Erros acumulados e perda de qualidade de dados</li>
                  <li>Aumento de custos com retrabalho </li>
                  <li>Insatisfação dos usuários</li>
                  <li>Baixa adesão da ferramenta (RPA) </li>
                  <li>Risco de não conformidades </li>
                </ul>
                <p>
                  Para minimizar esses impactos e produzir automações
                  eficientes, alocamos uma equipe dedicada a monitorar os robôs
                  e atuar prontamente na ocorrência de falhas. Fale agora com um
                  de nossos especialistas e eleve seu projeto de automação ao
                  próximo nível.
                </p>
                <AnchorLink
                  to={`/automacao-robotica-de-processos/performance#contact`}
                >
                  Solicite mais informações
                  <FaChevronRight />
                </AnchorLink>
              </div>
            </div>

            <div className={styles.hiRpaSection} style={{ marginTop: '3rem' }}>
              <div className={styles.title}>
                <h1>Confira cada etapa da metodologia de trabalho:</h1>
              </div>

              <div className={styles.processContainer}>
                <div className={styles.cardsContainer}>
                  <div className={styles.card}>
                    <span className={styles.label}>1ª etapa - Hypercare</span>
                    <p>
                      <strong>Acompanhamento</strong> após implementação do bot
                      em produção.
                    </p>
                    <p>
                      Suporte rápido e correção de falhas, ajustes finais e
                      otimizações.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>2ª etapa - Sustentação</span>
                    <p>
                      <strong>Garantia</strong> de que os bots continuem
                      operando corretamente
                    </p>
                    <p>
                      Envolve monitoramento, gestão de incidentes e melhoria
                      contínua.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>3ª etapa - Performance</span>
                    <p>
                      <strong>Gestão</strong> da performance de robôs com
                      controle de estatísticas.
                    </p>
                    <p>
                      Monitoramento de execuções, falhas e eficiência através de
                      B.I e infográficos.
                    </p>
                  </div>

                  <div className={styles.card}>
                    <span className={styles.label}>Entrega</span>
                    <p>
                      <strong>Resultado</strong> do processo robotizado.
                    </p>
                    <p>
                      Processamento de dados, relatórios, interface entre
                      sistemas diversos.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={styles.hiRpaSection}
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
            >
              <div className={styles.title}>
                <h1>O que será entregue ao final do processo?</h1>
              </div>
              <div className={styles.contentMid}>
                <div className={styles.text}>
                  <p>
                    O entregável de um robô (RPA) é{' '}
                    <strong>o resultado final gerado pela automação</strong>,
                    que pode ser um dado processado, um relatório ou mesmo a
                    integração de informações entre sistemas. Isso pode ser
                    medido na etapa Hi-Performance
                  </p>
                  <p>
                    Através da metodologia e dashboard de gestão de automações
                    desenvolvidos com exclusividade pela High Jump, é possível
                    ter a confiança de que os robôs estão executando
                    corretamente o que foi proposto.
                  </p>
                </div>
                <div className={styles.image}>
                  <StaticImage
                    src="../../../images/solutions/rpa/improvementResult.jpg"
                    alt="Conclusao do diagnostico"
                    title="Conclusao do diagnostico"
                    layout="fullWidth"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={rpaList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Consultoria para otimização de processos</h2>
                  <p>Evitando que erros ou redundâncias sejam automatizados</p>
                </div>
                <div className={styles.div2}>
                  <h2>Treinamento prático para as áreas de negócio</h2>
                  <p>Com o conteúdo preparatório para implementação do RPA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Estudo de viabilidade de projeto</h2>
                  <p>
                    Contemplando custo, retorno sobre o investimento e payback
                  </p>
                </div>
                <div className={styles.div4}>
                  <h2>Mão de obra especializada e exclusiva</h2>
                  <p>
                    Alocação de profissionais certificados e dedicação exclusiva
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />

          <FaqSection items={faqItems} />

          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default PerformancePage
